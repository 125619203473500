module.exports = {
  COOKIES: {
    D2ID: '_d2id',
    CP: 'cp',
    SSID: 'ssid',
  },
  HEADERS: {
    X_D2ID: 'x-d2id',
    X_REQUEST_DEVICE_ID: 'x-request-device-id',
  },
  TRACK_COOKIES_TITLE: 'TrackCookies',
  DROPOUT_TITLE: 'TrackDropout',
  DROPOUT_COMPLETED_PURCHASE: 'MShopsCompletedPurchase',
  DROPOUT_INDICATION: 'MShopsDropoutIndication',
  DROPOUT_PREFIX: 'DROPOUT_',
  THEMES: {
    MATERIAL: 'material',
  },
  NOT_ORDERABLE_COMPONENTS: ['DebugInfo', 'DebugInfoEditable', 'ReportPageEditable'],
  NOT_REMOVABLE_COMPONENTS: [
    'PaymentMethods', 'PaymentMethodsEditable',
    'ShoppingInfo', 'ShoppingInfoEditable',
    'BuyerInfo', 'BuyerInfoEditable',
    'Carousel', 'CarouselClipsEditable', 'ValuePropEditable',
    'LayoutWrapperEditable',
  ],
  COMPONENTS_REMOVE_ACTIONS: ['ProfileHeaderEditable'],
  DEVICES: {
    MOBILE: 'mobile',
    DESKTOP: 'desktop',
    TABLET: 'tablet',
  },
  PROFILE_HEADER_MENU: 'profileHeaderMenu',
  COMPONENTS_STATICS_TOP: [
    'recommendation-container',
    'carousel-clips',
    'coupon-nbt',
    'coupon-carousel',
    'tabbed-carousel_offers',
  ],
  PREVIEW: 'preview',
  DISABLE_METRICS: 'disable_metrics',
  REMOVE_PREVIEW_PARAM: 'remove-preview-param',
  COUPONS_INITIAL_SLIDE: 'coupons-initial-slide',
  CHILDREN_COMPONENTS_WITHOUT_ACTIONS: ['LayoutWrapperEditable', 'DynamicCarouselsEditable'],
};
